import React from "react"
import styled from "styled-components"

const UnderlineImageStyled = styled.div`
  position: relative;

  &::before {
    content: "";
    left: 50%;
    width: 90%;
    height: 6rem;
    bottom: -20px;
    position: absolute;
    background-color: ${props => props.theme.color.primary};
    transform: translateX(-50%);
    border-radius: 5px;
  }

  &-img {
    border-radius: 5px;
    z-index: 20;
  }
`

const UnderlineImage = ({ children }) => {
  return (
    <UnderlineImageStyled className="underline-image">
      {children}
    </UnderlineImageStyled>
  )
}

export default UnderlineImage