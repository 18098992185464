import React from "react"
import { useTranslation } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import { mediaQueries } from "../style/mediaQueries"

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 6rem;
  width: 100%;
`

const Title = styled.h2`
  text-align: center;
  position: relative;
  padding: 0 0 1.5rem;
  margin-bottom: 2rem;

  &:after, &:before {
    position: absolute;
    content: "";
    left: 50%;
    height: 3px;
    background: ${props => props.theme.color.primary};
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  &:before {
    bottom: 0;
    width: 6rem;
  }

  &:after {
    bottom: -6px;
    width: 3rem;
  }

  span {
    font-size: inherit;
    color: ${props => props.theme.color.primary};
  }
`

const SubTitle = styled.p`
  width: 60%;
  @media only screen and (${mediaQueries.tabLand}) {
    width: 70%;
  }
  @media only screen and (${mediaQueries.tabLand}) {
    width: 90%;
  }
  text-align: center;
  font-size: 1.7rem;
`

const SectionTitle = ({ prefixTitle, title, subTitle }) => {
  const { t } = useTranslation()
  prefixTitle = prefixTitle ? prefixTitle : t("common.prefix.title")

  return (
    <TitleContainer>
      <Title>{prefixTitle} <span>{title}</span></Title>
      <SubTitle>{subTitle}</SubTitle>
    </TitleContainer>
  )
}

export default SectionTitle