import React from "react"
import SectionTitle from "./sectionTitle"
import { useTranslation } from "gatsby-plugin-react-i18next"
import styled from "styled-components"
import ContactForm from "./form/ContactForm"

const BackgroundSection = styled.div`
  background-color: ${props => props.theme.color.lightGrey};
  grid-column: full-start / full-end;
  grid-row: 9 / 9;
  height: 100%;
`

const Contact = ({ title, prefixTitle, withBackground }) => {
  const { t } = useTranslation()

  let Section = styled.section`
    grid-column: center-start / center-end;
    display: flex;
    flex-direction: column;
    align-items: center;
  `

  if (withBackground) {
    Section = styled(Section)`
      grid-row: 9 / 9;
      padding: 11.5rem 0;
    `
  }

  return (
    <>
      <Section id="contact">
        <SectionTitle
          prefixTitle={prefixTitle}
          title={title}
          subTitle={t("contact.subTitle")} />

        <ContactForm />
      </Section>
      {withBackground ? <BackgroundSection /> : ""}
    </>
  )
}

export default Contact