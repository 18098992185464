import React, { useState } from "react"
import { InputForm, TextareaForm } from "./inputForm"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { useTranslation } from "gatsby-plugin-react-i18next"
import axios from "axios"
import styled from "styled-components"
import { mediaQueries } from "../../style/mediaQueries"

const SubmitButtonContainer = styled.div`
  grid-column: 1 / -1;
  justify-self: center;
  z-index: 90;

  .contact-btn {
    font-size: 1.6rem;
  }
`

const FormStyled = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(5, min-content);
  grid-gap: 3rem;
  width: 80%;

  @media only screen and (${mediaQueries.phonePort}) {
    width: 100%;
  }
`

const FormResponse = styled.p`
  grid-row: 4/4;
  grid-column: 1 / -1;
  text-align: center;
  color: ${props => props.isError ? props.theme.color.error : props.theme.color.text};
`

const ContactForm = () => {
  const { executeRecaptcha } = useGoogleReCaptcha()
  const { t } = useTranslation()
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null
  })

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg }
    })
    if (ok) {
      form.reset()
    }
  }

  const handleOnSubmit = e => {
    e.preventDefault()

    // Check if the captcha was skipped or not
    if (!executeRecaptcha) {
      return
    }

    setServerState({ submitting: true })
    const recaptchaApi = process.env.NODE_ENV === "production" ?
      "/.netlify/functions/recaptcha" :
      "http://localhost:8888/.netlify/functions/recaptcha"
    const form = e.target

    executeRecaptcha("contact").then(result => {
      axios({
        method: "post",
        url: recaptchaApi,
        data: {
          token: result
        }
      }).then(r => {
        if (r.data.score > 0.5) {
          axios({
            method: "post",
            url: "https://getform.io/f/f5382255-c33a-4d87-998c-05c5c792a6a2",
            data: new FormData(form)
          })
            .then(r => {
              handleServerResponse(true, t("contact.form.confirmation"), form)
            })
            .catch(r => {
              handleServerResponse(false, r.response.data.error, form)
            })
        } else {
          handleServerResponse(false, t("contact.form.error.recaptcha"), form)
        }
      }).catch(r => {
        handleServerResponse(false, "Recaptcha error, contact the administrator", form)
      })
    })
  }

  return (
    <FormStyled id="contactForm" onSubmit={handleOnSubmit} acceptCharset="UTF-8">
      <InputForm id="name" name="name" label={t("contact.form.name")} type="text"
                 placeholder={t("contact.form.name")} icon="user" iconPrefix="far" />

      <InputForm id="email" name="email" label={t("contact.form.email")} type="email"
                 placeholder={t("contact.form.email")} icon="at" iconPrefix="fas" />

      <InputForm id="objet" name="objet" label={t("contact.form.object")} type="text"
                 placeholder={t("contact.form.object")} icon="envelope" iconPrefix="far"
                 isLarge={true} />

      <TextareaForm id="message" name="message" label={t("contact.form.message")}
                    placeholder={t("contact.form.message")} icon="comment-dots" iconPrefix="far"
                    isLarge={true} />

      <SubmitButtonContainer>
        <button type="submit" id="contactBtn" className="default-button contact-btn"
                disabled={serverState.submitting}>{t("contact.form.button")}
        </button>
      </SubmitButtonContainer>
      {
        serverState.status && (
          <FormResponse isError={!serverState.status.ok}>
            {serverState.status.msg}
          </FormResponse>
        )
      }
    </FormStyled>
  )
}

export default ContactForm