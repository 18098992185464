import React from "react"
import { faAt } from "@fortawesome/free-solid-svg-icons"
import { faCommentDots, faEnvelope, faUser } from "@fortawesome/free-regular-svg-icons"
import { library, findIconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import styled from "styled-components"
library.add(faUser, faAt, faEnvelope, faCommentDots)


const FormGroup = styled.div`
  position: relative;
`

const Input = styled.input`
  font-family: ${props => props.theme.fonts.text};
  font-size: 1.6rem;
  width: 100%;
  display: block;
  border-radius: 5px;
  padding: 16px 16px 16px 40px;
  border: 1px solid ${props => props.theme.color.softGrey};
  transition: all 0.25s ease;
  box-sizing: border-box;
  appearance: none;

  &:focus {
    border-color: ${props => props.theme.color.black};
  }
`

const Textarea = styled.textarea`
  font-family: ${props => props.theme.fonts.text};
  font-size: 1.6rem;
  width: 100%;
  display: block;
  border-radius: 5px;
  padding: 16px 16px 16px 40px;
  border: 1px solid ${props => props.theme.color.softGrey};
  transition: all 0.25s ease;
  box-sizing: border-box;
  appearance: none;

  &:focus {
    border-color: ${props => props.theme.color.black};
  }
`

const Label = styled.label`
  position: absolute;
  height: 1.6rem;
  width: 2.4rem;
  clip: rect(1px,1px,1px,1px);
  border: 0;
  overflow: hidden
  top: 18px;
  left: 12px;
`

const FontAwesomeIconStyled = styled(FontAwesomeIcon)`
  position: absolute;
  height: 1.6rem;
  width: 2.4rem !important;
  top: 18px;
  left: 12px;
`

export const InputForm = ({
                     type, name, id, placeholder, label,
                     isRequired = true,
                     icon, iconPrefix, isLarge = false
                   }) => {

  let FormGroupStyled = FormGroup
  if (isLarge) {
    FormGroupStyled = styled(FormGroup)`
      grid-column: 1 / -1;
    `
  }

  const iconDefinition = findIconDefinition({ prefix: iconPrefix, iconName: icon })
  return (
    <FormGroupStyled>
      <Input type={type} name={name} id={id}
             placeholder={placeholder}
             required={isRequired ? "required" : ""} />
      <Label htmlFor={id}>{label}</Label>
      <FontAwesomeIconStyled icon={iconDefinition} />
    </FormGroupStyled>
  )
}

export const TextareaForm = ({
                            type, name, id, placeholder, label,
                            isRequired = true,
                            icon, iconPrefix, isLarge = false
                          }) => {

  let FormGroupStyled = FormGroup
  if (isLarge) {
    FormGroupStyled = styled(FormGroup)`
      grid-column: 1 / -1;
    `
  }

  const iconDefinition = findIconDefinition({ prefix: iconPrefix, iconName: icon })
  return (
    <FormGroupStyled>
      <Textarea name={name} id={id}
                placeholder={placeholder}
                cols="20" rows="5"
                required={isRequired ? "required" : ""}></Textarea>
      <Label htmlFor={id}>{label}</Label>
      <FontAwesomeIconStyled icon={iconDefinition} />
    </FormGroupStyled>
  )
}
