import React from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUp } from "@fortawesome/free-solid-svg-icons"
import ScrollTo from "../../api/scrollTo"
import { useTranslation } from "gatsby-plugin-react-i18next"

const ScrollToStyled = styled(ScrollTo)`
  border: none;
  cursor: pointer;
  font-size: 1.6rem;
  position: fixed;
  right: 1rem;
  bottom: 0;
  z-index: 100;
  width: 5.6rem;
  height: 5.6rem;
  line-height: 5.6rem;
  border-radius: 0.5rem;
  text-align: center;
  opacity: 0;
  visibility: hidden;
  background: ${props => props.theme.color.primary};
  transition: all 0.25s ease;
  color: ${props => props.theme.color.white};
  box-shadow: 0 0 0 0 ${props => props.theme.color.primary};
  animation: scrollPulse 2s infinite forwards;

  &.active {
    opacity: 1;
    bottom: 2rem;
    visibility: visible;
  }

  &:hover {
    animation: none;
  }
`

const TopScroll = ({target}) => {
  const { t } = useTranslation()
  return (
    <ScrollToStyled id={"scroll-top-btn"} target={target} className={"scroll-top-btn"} offset={0} title={t("common.btn.go.top")}>
      <FontAwesomeIcon icon={faArrowUp}/>
    </ScrollToStyled>
  )
}

export default TopScroll